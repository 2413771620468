.site-footer {
	font-size: 2.4vw;
	margin-top: auto;

	@include media-breakpoint-up(sm) {
		font-size: 12/16 * 1rem;
	}

	a {
		color: inherit;
	}

	.list-inline-item:not(:last-child) {
		&:after {
			content: '|';
			margin-left: 0.325rem;
		}
	}
}