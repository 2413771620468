.page-content {
	min-height: 300px;
	//overflow: hidden;

	a:not(.btn) {
		text-decoration: underline;
	}
}

.table-wrapper {
	overflow: auto;

	.table {
		min-width: 500px;
	}
}

.dataTables_wrapper {
	.row {
		align-items: center;

		.col-md-6 {
			& + .col-md-6 {
				@include media-breakpoint-up(md) {
					display: flex;
					justify-content: flex-end;
				}
			}
		}
	}

	.form-control-sm {
		display: inline-block;
		width: auto;
	}

	.dataTables_length,
	.dataTables_filter {
		label {
			margin-bottom: 0;
		}
	}

	.dataTables_length {
		.form-control-sm {
			margin: 0 0.25rem;
		}
	}

	.dataTables_filter {
		margin-top: 0.5rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
		}

		.form-control-sm {
			margin-left: 0.5rem;
			max-width: calc(100% - 63px);
		}		
	}

	.dataTable {
		margin: 0.75rem 0;
		font-size: 14/16 * 1rem;
	}

	.dataTables_processing {
		margin-bottom: 0.75rem;
		padding: 0.25rem 0.5rem;
		font-size: 12/16 * 1rem;
	}

	.dataTables_info,
	.dataTables_paginate {
		font-size: 12/16 * 1rem;
	}

	.dataTables_paginate {
		margin-top: 0.5rem;

		@include media-breakpoint-up(md) {
			display: flex;
			justify-content: flex-end;
			margin-top: 0;
		}

		.pagination {
			margin-bottom: 0;

			a {
				text-decoration: none;
			}
		}
	}
}