.site-header {
	a {
		color: inherit;
		display: block;
		max-width: 230px;
		@include media-breakpoint-up(md) {
			max-width: none;
		}
	}

	.branding {
		img {
			height: 25px;

			@include media-breakpoint-up(md) {
				height: 40px;
			}

			&:not(:last-child) {
				border-right: 1px solid $gray-600;
				padding-right: 0.75rem;
				margin-right: 0.25rem;
			}
		}
	}
}

.hero {
	display: grid;
	grid-template:
		"hero"
		"content";
	place-items: center;
	place-content: start;
	overflow: hidden;
	max-height: clamp(450px, 50vh, 600px);

	@include media-breakpoint-up(lg) {
		grid-template: "hero";
	}
}

.hero > * {
	@include media-breakpoint-up(lg) {
		grid-area: hero;
	}
}

.hero .hero-content {
	max-width: 1300px;
	width: calc(100% - 30px);
	margin: 0 15px;
	color: $dark;
	grid-area: content;

	@include media-breakpoint-up(lg) {
		color: $brand-white;
		grid-area: hero;

	}

	@media (min-width: 1330px) {
		margin: 0;
		width: 100%;
	}
}

.hero .hero-body {
	@include media-breakpoint-up(md) {
		max-width: 600px;
	}
}

.hero .hero-image {
	width: 100%;
	height: auto;
	aspect-ratio: 2880 / 556;
	object-fit: cover;
	z-index: -1;
	grid-area: hero;

	@include media-breakpoint-up(lg) {
		grid-area: hero;

	}
}