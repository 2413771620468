label {
	cursor: pointer;
	font-family: $headings-font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.48px;
	padding-left: 10px;
}

.form-check-input+label {
	padding-left: 0;
	padding-top: 3px;
}

.form-check-input {
	margin-top: 0.4rem;
}


	.form-check-input + label {
	margin-bottom: 0;
}

input[type=checkbox],
input[type=radio] {
	accent-color: $primary;
}