html {
	min-height: 100%;
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.container-fluid {
	max-width: 1300px;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: $font-family-sans-header;
	//text-transform: uppercase;
}

.fw-700 {
	font-weight: 700;
}

.account-details {
	@include media-breakpoint-up(lg) {
		position: sticky;
		top: 20px;
	}
}

.cc-window {
	background-color: $black !important;
}
.cc-button {
	background-color: $primary !important;
	border-radius: 0 !important;
	&:hover {
		background-color: $primaryAlt !important;
	}
}
.cc-message {
	font-family: $font-family-sans-serif !important;
}

